import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"

import "./layout.scss"

interface LayoutProps {
  pageClass?: string
}
const Layout: React.FC<LayoutProps> = ({ pageClass = "", children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={classNames("layout", pageClass)}>
      <main>{children}</main>
    </div>
  )
}

export default Layout
